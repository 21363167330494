
    import { Component, Vue } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'
    import {
        registrationProcess,
        registrationWay,
        payWay,
        refundNotice,
        changingClasses,
        commonProblem
    } from './../../../store/config_data'
    import { registrationProcessItem }from './../../../store/interface'
    @Component({
        name: 'HelpCenter'
    })
    export default class HelpCenter extends Vue {
        private curRegistrationProcess: registrationProcessItem = registrationProcess
        private curRegistrationWay: registrationProcessItem = registrationWay
        private curPayWay: registrationProcessItem = payWay
        private curRefundNotice: registrationProcessItem = refundNotice
        private curChangingClasses: registrationProcessItem = changingClasses
        private curCommonProblem: registrationProcessItem = commonProblem
    }

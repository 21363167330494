
    import { Component, Vue, Watch, Emit } from 'vue-property-decorator'
    import { mapGetters, mapMutations, mapActions } from 'vuex'

    import { Caxios } from './../../../../utils/axios'

    @Component({
        name: 'TeacherList',
    })
    export default class TeacherList extends Vue {
        private curTeacherItemData: any = null
        formatSessionStorage (): void {
          sessionStorage.removeItem('aboutUs_teacherIntroduced_teacherId')
          sessionStorage.setItem('aboutUs_teacherIntroduced_componentName', 'TeacherList')
        }
        @Emit()
        private teacherItem (): void {
          this.formatSessionStorage()
        }
      private setSummary (str: string): void{
        const stringList: any = []
        const stringStartIndexList: any = []
        const stringEndIndexList: any = []
        str.replace(/<p[^>]*>/gi, function(match: any, capture: any){
          stringStartIndexList.push(capture)
          return ''
        });
        str.replace(/<\/p[^>]*>/gi, function(match: any, capture: any){
          stringEndIndexList.push(capture+4)
          return ''
        });
        let obj: any = {}
        Object.keys(stringEndIndexList).forEach(i => {
          const html = str.substring(stringStartIndexList[i], stringEndIndexList[i])
          if (Number(i) % 2 === 0) {
            obj.title = html
          } else {
            obj.content = html
            stringList.push(obj)
            obj = {}
          }

        })
        return stringList
      }
      private signUpEvent (): void {
        let _this: any = this
        _this.$router.push('/course/index')
        this.formatSessionStorage()
      }

      beforeMount() {
          const curTeacher: any = sessionStorage.getItem('curTeacherItemData')
          this.curTeacherItemData = JSON.parse(curTeacher)
          console.log('111',this.curTeacherItemData)

        }
    }


    import { Component, Vue } from 'vue-property-decorator'
    import { aboutUsNavItem, enterpriseDataItem } from './../../../store/interface'
    import { enterpriseAboutData, enterpriseTeachingData, enterpriseTotalData } from './../../../store/config_data'
    @Component({
        name: 'EnterpriseIntroduction'
    })
    export default class EnterpriseIntroduction extends Vue {
        private curEnterpriseAboutData?: Array<enterpriseDataItem> = [ ...enterpriseAboutData ]
        private curEnterpriseTeachingData?: Array<enterpriseDataItem> = [ ...enterpriseTeachingData ]
        private curEnterpriseTotalData?: Array<aboutUsNavItem> = [ ...enterpriseTotalData ]
    }
